@if (isUserMimic()) {
  <div class="user-mimic-status-bar">
    <div class="ps-2">
      <span>You are currently working on behalf of&nbsp;</span>
      <span class="fw-bold">{{ userName() }}</span>
    </div>
    <div class="pe-5">
      <button
        mat-stroked-button
        color="primary"
        type="button"
        class="action-button"
        data-testId="Mimic stop button"
        [disabled]="isLoading()"
        (click)="onClick()"
      >
        Release User
      </button>
    </div>
  </div>
}
